:root {
  --font-body: Arial, sans-serif;
  --font-pnt: Wallpoet, cursive;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --bgcolor: hsl(229, 47%, 20%);
  --scorecolor: hsl(349, 93%, 64%);
  --ptnbtncolor: hsl(224, 44%, 73%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
  color: var(--white);
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.scoreboard {
  background-color: var(--bgcolor);
  width: 90vh;
  height: 50%;
  display: grid;
  align-items: baseline;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 10vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.9375rem;
}
.scoreboard .home__title {
  margin-top: 6.25rem;
  margin-bottom: 1.25rem;
  text-align: center;
  width: 9.375rem;
  height: 2.25rem;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
}
.scoreboard .home__score {
  background-color: var(--black);
  width: 9.6875rem;
  height: 7.5rem;
  font-family: var(--font-pnt);
  color: var(--scorecolor);
  text-align: center;
  font-size: 5.5rem;
  margin: 0;
  padding-top: 0.75rem;
  border: solid 0.125rem whitesmoke;
}
.scoreboard .home__wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.25rem;
  width: 9.6875rem;
  height: 2.5rem;
}
.scoreboard .home--pnt {
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  border: solid 0.125rem var(--ptnbtncolor);
  border-radius: 0.3125rem;
  color: var(--ptnbtncolor);
  font-size: 1rem;
  letter-spacing: 0.25rem;
}
.scoreboard .guest__title {
  margin-top: 6.25rem;
  margin-bottom: 1.25rem;
  text-align: center;
  width: 9.375rem;
  height: 2.25rem;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
}
.scoreboard .guest__score {
  background-color: var(--black);
  width: 9.6875rem;
  height: 7.5rem;
  font-family: var(--font-pnt);
  color: var(--scorecolor);
  text-align: center;
  font-size: 5.5rem;
  margin: 0;
  padding-top: 0.75rem;
  border: solid 0.125rem whitesmoke;
}
.scoreboard .guest__wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.25rem;
  width: 9.6875rem;
  height: 2.5rem;
}
.scoreboard .guest--pnt {
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  border: solid 0.125rem var(--ptnbtncolor);
  border-radius: 0.3125rem;
  color: var(--ptnbtncolor);
  font-size: 1rem;
  letter-spacing: 0.25rem;
}
.scoreboard .reset-btn {
  position: absolute;
  right: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 19.875rem;
}
.scoreboard .rst-btn {
  width: 5.3125rem;
  height: 2.5rem;
  background: none;
  border: solid 0.125rem var(--ptnbtncolor);
  border-radius: 0.3125rem;
  color: var(--ptnbtncolor);
  font-size: 1rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
}